/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from 'src/components/GTM';

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="cs" />
          <body />
          
          <meta charSet="utf-8" />
          <title>Pleť s nedokonalostmi</title>
          <meta
            property="og:url"
            content="/plet-s-nedokonalostmi/"
          />
          <meta property="og:site_name" content="neutrogena-kosmetika.cz" />
          <meta property="og:title" content="Pleť s nedokonalostmi" />
          <meta
            property="og:image"
            content="http://www.neutrogena.com.pl/neutrogena-logo.png"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="640" />
          <meta property="og:image:height" content="640" />
          <link
            rel="image_src"
            href="http://www.neutrogena.com.pl/neutrogena_logo.gif"
          />
          <style
            type="text/css"
            cssText='
  @font-face {
      font-family: "ultraLightFont";
      src: url("/themes/neutrogenacz/css/fonts/787ee748-9cce-45a0-910b-6b5c6e87e327.eot?#iefix");
      src: url("/themes/neutrogenacz/css/fonts/787ee748-9cce-45a0-910b-6b5c6e87e327.eot?#iefix") format("eot"),
      url("/themes/neutrogenacz/css/fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff") format("woff"),
      url("/themes/neutrogenacz/css/fonts/411a88fe-e483-4fb8-af42-8369ebb1138d.ttf") format("truetype"),
      url("/themes/neutrogenacz/css/fonts/6dfe33a4-0ad5-4c85-8e01-f48ecfe3c167.svg#6dfe33a4-0ad5-4c85-8e01-f48ecfe3c167") format("svg");
  }
    '
          />
          <link rel="shortcut icon" href="/malinanordycka/img/favicon.ico" type="image/x-icon" />
          <link rel="icon" href="/malinanordycka/img/favicon.ico" type="image/x-icon" />
          <script src="/themes/neutrogenacz/js/jquery-3.5.1.js" />
          <script src="/themes/neutrogenacz/js/jquery-migrate-3.3.0.js" />
          <script type="text/javascript" src="/themes/neutrogenacz/js/jquery-migrate-1.4.1.js" />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.mousewheel-3.0.4.pack.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.fancybox-init.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/fancybox/jquery.fancybox-1.3.4.pack.js"
          />
          
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/neutrogenacz/fancybox/jquery.fancybox-1.3.4.css"
            media="screen"
          />
          <link
            rel="stylesheet"
            href="/themes/neutrogenacz/projekktor/theme/proj.css"
            type="text/css"
            media="screen"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/projekktor/projekktor-1.2.19r188.min.js"
          />
          <script
            type="text/javascript"
            src="/themes/neutrogenacz/projekktor/projekktor-init.js"
          />
          <link
            href="/themes/neutrogenacz/css/cookie.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/neutrogenacz/css/style.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/neutrogenacz/css/swp.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/neutrogenacz/css/sp.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/simple/css/demo.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/neutrogenacz/css/demo.css"
          />
          <script
            type="text/javascript"
            src="/themes/simple/js/core.js"
          />
          <script
            type="text/javascript"
            src="/themes/simple/js/products.js"
          />
          <script
            type="text/javascript"
            src="/themes/simple/js/customInput.jquery.js"
          />
          <script
            type="text/javascript"
            src="/themes/simple/js/customInput_init.js"
          />
          <link
            href="/themes/neutrogenacz/css/style.css"
            type="text/css"
            rel="stylesheet"
          />
          <link rel="stylesheet" type="text/css" href="/staticcss/styles.css" />
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
