import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s);\njs.id = id;\njs.src = \"//connect.facebook.net/pl_PL/all.js#xfbml=1&appId=654267651256301\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        
        <div className="boper">
          <div
            className="wrapper"
            style={{
              "font-family": "'Arial', sans-serif"
            }}
          >
            <header>
              <h1 className="logo">
                <a href="/" />
              </h1>
              <nav>
                <a
                  href="/produkty/"
                  style={{
                    "font-family": "'Arial', sans-serif"
                  }}
                >
                  Produkty
                </a>
                <span>| </span>
                <a
                  href="/neutrogena/o-nas/"
                  className="link"
                  style={{
                    "font-family": "'Arial', sans-serif"
                  }}
                >
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a
                  href="/budte-krasna/"
                  className="link"
                  style={{
                    "font-family": "'Arial', sans-serif"
                  }}
                >
                  Buďte krásná
                </a>
                <span>| </span>
                <a
                  href="/nordic-berry/"
                  className="link"
                  style={{
                    "font-family": "'Arial', sans-serif"
                  }}
                >
                  Nordic Berry
                </a>
                <span />
                <a
                  href="#"
                  className="current"
                  style={{
                    "font-family": "'Arial', sans-serif"
                  }}
                >
                  Pleť s nedokonalostmi
                </a>
                <span />
              </nav>
            </header>
          </div>
          <section id="sp">
            <h1
              className="title"
              style={{
                "font-family": "'UltraLightFont', 'Arial', sans-serif"
              }}
            >
              Objevte, co vše je možné v péči o pleť v&nbsp;boji s&nbsp;pupínky!
            </h1>
            <section className="videos">
              <div>
                <aside>
                  <a
                    className="ytvid video"
                    href="https://www.youtube.com/embed/zezPqf5Kvdo?autoplay=1"
                  >
                    <img
                      src="/assets/Uploads/_resampled/SetWidth960-poster.png"
                      alt=""
                    />
                  </a>
                </aside>
              </div>
            </section>
            <section className="rutyna">
              <div className="about">
                <div className="wysiwyg">
                  <h4
                    className="title"
                    style={{
                      "font-family": "'ultraLightFont', 'Arial', sans-serif"
                    }}
                  >
                    Rutinní péče
                  </h4>
                  <p>
                    Cesta ke krásné pleti bez pupínků začíná jejím správným
                    čistěním. NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup>{" "}
                    Spot Proofing<sup>™</sup> je pleťová řada určená ke
                    každodenní rutinní péči v boji proti nedokonalostem a
                    pupínkům. Pečujte o svoji pleť ve 3 krocích.
                  </p>
                </div>
                <div className="photo">
                  <img src="/themes/neutrogenacz/img/rutyna.png" />
                </div>
              </div>
              <ul className="items">
                <li>
                  <p className="name">1. UMYJTE</p>
                  <p>
                    Pleť umyjte ráno a večer, použijte NEUTROGENA<sup>®</sup>{" "}
                    VISIBLY CLEAR<sup>®</sup>
                    Spot Proofing<sup>™</sup> Čisticí gel na obličej.
                  </p>
                </li>
                <li>
                  <p className="name">2. VYČISTĚTE</p>
                  <p>
                    Pleť vyčistěte pomocí NEUTROGENA<sup>®</sup> VISIBLY CLEAR
                    <sup>®</sup> Spot Proofing<sup>™</sup>
                    Vyhlazujícího peelingu a Micelárního čisticího tonika.
                  </p>
                </li>
                <li>
                  <p className="name">3. OŠETŘETE</p>
                  <p>
                    Chraňte pleť před pupínky a&nbsp;pečujte o ni pomocí
                    NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup>
                    Spot Proofing<sup>™</sup> Nemastného hydratačního krému na
                    obličej. V&nbsp;závislosti na potřebách pleti používejte
                    NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                    Proofing<sup>™</sup> 2&nbsp;v&nbsp;1 Čisticí emulzi a
                    masku.“
                  </p>
                </li>
              </ul>
              <div className="products">
                <div className="wysiwyg">
                  <h4
                    className="title"
                    style={{
                      "font-family": "'ultraLightFont', 'Arial', sans-serif"
                    }}
                  >
                    Seznamte se s výrobky z řady NEUTROGENA<sup>®</sup> VISIBLY
                    CLEAR<sup>®</sup> Spot Proofing<sup>™</sup>
                  </h4>
                  <p
                    style={{
                      "font-family": "'ultraLightFont', 'Arial', sans-serif"
                    }}
                  >
                    Výrobky z řady NEUTROGENA<sup>®</sup> VISIBLY CLEAR
                    <sup>®</sup> Spot Proofing<sup>™</sup> kombinují jemné
                    působení se silnými účinky <br />
                    pro odstranění pupínků. <br />
                    <br />
                  </p>
                  <div
                    style={{
                      "-webkit-text-align": "center",
                      "text-align": "center",
                      "font-family": "'ultraLightFont', 'Arial', sans-serif"
                    }}
                  >
                    Výrobky z řady NEUTROGENA<sup>®</sup> VISIBLY CLEAR
                    <sup>®</sup> Spot Proofing<sup>™</sup>:<br />
                    <div
                      className="advantages"
                      style={{
                        "margin-left": "20px"
                      }}
                    >
                      • neucpávají póry
                      <br /> • vyznačují se příjemnou a svěží vůní
                      <br /> • jsou charakteristické nemastným složením s
                      kyselinou salicylovou
                    </div>
                  </div>
                </div>
                <div className="line">
                  <div className="item item-col-3">
                    <div className="photo">
                      <img src="/site/assets/sp/products/produkt1.png" />
                    </div>
                    <p className="name">
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                      Proofing<sup>™</sup>
                    </p>
                    <p
                      className="subtitle"
                      style={{
                        "font-family": "'Arial', sans-serif",
                        "font-style": "italic"
                      }}
                    >
                      Čisticí gel{" "}
                    </p>
                    <div className="desc">
                      <p>
                        Gelové složení, které po napěnění odstraňuje nečistoty,
                        pomáhá redukovat pupínky, a přitom nenarušuje přirozenou
                        ochrannou bariéru pleti.
                      </p>
                    </div>
                    <p className="ml">200 ml</p>
                  </div>
                  <div className="item item-col-3">
                    <div className="photo">
                      <img src="/site/assets/sp/products/produkt2.png" />
                    </div>
                    <p className="name">
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                      Proofing<sup>™</sup>
                    </p>
                    <p
                      className="subtitle"
                      style={{
                        "font-family": "'Arial', sans-serif",
                        "font-style": "italic"
                      }}
                    >
                      Vyhlazující peeling
                    </p>
                    <div className="desc">
                      <p>
                        Obsahuje abrazivní látky přírodního původu. Složení
                        pomáhá posílit přirozený proces obnovy pleti a zároveň
                        odstraňovat pupínky.{" "}
                      </p>
                    </div>
                    <p className="ml">150 ml</p>
                  </div>
                  <div className="item item-col-3">
                    <div className="photo">
                      <img src="/site/assets/sp/products/produkt3.png" />
                    </div>
                    <p className="name">
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                      Proofing<sup>™</sup>
                    </p>
                    <p
                      className="subtitle"
                      style={{
                        "font-family": "'Arial', sans-serif",
                        "font-style": "italic"
                      }}
                    >
                      Micelární čisticí tonikum
                    </p>
                    <div className="desc">
                      <p>
                        Bezalkoholové složení
                        <br />s obsahem micel účinně odstraňuje nečistoty a
                        nevysušuje pokožku.
                      </p>
                    </div>
                    <p className="ml">200 ml</p>
                  </div>
                </div>
                <div className="line">
                  <div className="item item-col-2">
                    <div className="photo">
                      <img src="/site/assets/sp/products/produkt4.png" />
                    </div>
                    <p className="name">
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                      Proofing<sup>™</sup>
                    </p>
                    <p
                      className="subtitle"
                      style={{
                        "font-family": "'Arial', sans-serif",
                        "font-style": "italic"
                      }}
                    >
                      Nemastný hydratační krém
                    </p>
                    <div className="desc">
                      <p>
                        Lehký, snadno vstřebatelný krém zajišťuje celodenní
                        hydrataci pleti, kterou zanechává hebkou a zároveň
                        neucpává póry.
                      </p>
                    </div>
                    <p className="ml">50 ml</p>
                  </div>
                  <div className="item item-col-2">
                    <div className="photo">
                      <img src="/site/assets/sp/products/produkt5.png" />
                    </div>
                    <p className="name">
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Spot
                      Proofing<sup>™</sup>
                    </p>
                    <p
                      className="subtitle"
                      style={{
                        "font-family": "'Arial', sans-serif",
                        "font-style": "italic"
                      }}
                    >
                      2v1 čisticí emulze a maska
                    </p>
                    <div className="desc">
                      <p>
                        Lze používat každý den jako pěnící čisticí emulzi nebo
                        nanášet jako masku v&nbsp;závislosti na potřebách pleti.
                      </p>
                    </div>
                    <p className="ml">150 ml</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="effect">
              <div className="wrapper">
                <div className="photo">
                  <img src="/site/assets/sp/covers/produkt.png" />
                </div>
                <div className="wysiwyg">
                  <p className="name">
                    Bojujte proti pupínkům,
                    <br /> ne proti své pleti
                  </p>
                  <p>
                    Nová řada výrobků NEUTROGENA<sup>®</sup> VISIBLY CLEAR
                    <sup>®</sup> Spot Proofing<sup>™</sup>
                    působí od vnější vrstvy kůže a šetrně odstraňuje nečistoty.
                    Aktivní složka ve formě kyseliny salicylové pomáhá bojovat s
                    nedokonalostmi pokožky.
                    <br />
                    <br />
                    Použitá technologie ClearDefend<sup>™</sup> pomáhá redukovat
                    pupínky, proniká hluboko do pórů, a zároveň zanechává <br />
                    v neporušeném stavu většinu pro pleť nezbytných, výživných
                    látek. Posiluje tak přirozený „ochranný štít“ pleti a
                    zabraňuje tak vzniku nových pupínků.
                  </p>
                </div>
              </div>
            </section>
            <div className="slogan" />
          </section>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\nvar address = ' /produkty/ ';\n$(\"#Form_ProductsFilter_action_ClearAction\").click(function () {\n$('input').attr('checked', false);\n$('label.checked').attr('class', '');\nwindow.location = (address);\n});\n$(\"#allproducts\").click(function () {\nwindow.location = (address);\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(function () {\n$('#sp nav a,#sp .videos > a').click(function () {\n$('html,body').animate({\nscrollTop: $($(this).attr('href')).offset().top + 'px'\n}, 1000);\nreturn false;\n}).each(function () {\nvar h = $(this).attr('href');\n$(this).attr('href', h.substring(h.indexOf('#')));\n});\nvar $sp = $('#sp');\n$sp.find('a.vid').click(function () {\n$sp.find('.videos').append('<figure><div><video controls preload=\"auto\"><source type=\"video/mp4\" src=\"' + $(this).attr('href') + '\" /></div><dfn></dfn></figure>');\n$sp.find('.videos figure video').each(function () {\nthis.load();\n$(this).on('canplaythrough', function () {\nthis.play();\n});\n});\n$sp.find('.videos figure dfn').click(function () {\n$sp.find('.videos figure video').each(function () {\nthis.pause();\n$(this).remove();\n});\n$sp.find('.videos figure').empty().remove();\n});\nreturn false;\n});\n$sp.find('a.ytvid').click(function () {\n$('body').append('<section class=\"yt\"><div><iframe src=\"' + $(this).attr('href') + '\"></iframe></div><dfn></dfn></section>');\n$('body > section.yt dfn').click(function () {\n$('body > section.yt').remove();\nreturn false;\n});\nreturn false;\n});\n$('#accept-cookies-checkbox').click(function (e) {\n$('#cookies-message-container').hide();\n});\n$('#btn-close-cookie').click(function (e) {\n$('#cookies-message-container').hide();\n})\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
